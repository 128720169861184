import React from "react"
import { useClasses } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import ContactForm from '../components/ContactForm'
import MEETING from '../images/demo/meeting.svg'

export default function FeaturesPage() {
  const root = useClasses()
  return (
    <Layout short>
      <SEO title='Demo' description='Book a short OneOffice Demo with one of our experts'/>
      <main className="main" ref={root}>

        <div className="heading-separator"/>
        <h1 className="title">Demo</h1>
        <h2 className="subtitle">Choose the most convenient time and we'll be there!</h2>
        <p className="description">
          We are excited to meet you! You will get an email from us with a calendar invitation and a link for a video call. Please check your Spam folder (you never know!). <span className='text-sm text-gray-600'>Please wait while the form below loads, we are busy checking our calendars.</span>
        </p>
        <div className="flex flex-col md:flex-row gap-6 my-12 items-center justify-center">
          <img src={MEETING} alt="Meeting inmage" className='md:w-1/2'/>
          <ContactForm messagePrefix="Demo request\n\n" messageLabel="When is it most convenient?"/>
        </div>        
      </main>
    </Layout>
  )
}

